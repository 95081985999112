import * as React from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../components/button"

import organisms from "../images/icons/organisms.svg"
import whatIsLivtencity from "../images/icons/what-is-livtencity.svg"
import support from "../images/icons/support.svg"

const ThreeCards = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "backgrounds/three-cards.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 750
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  const cards = [
    {
      image: organisms,
      text: (
        <>
          Understanding CMV <br />
          after transplant
        </>
      ),
      button: { text: "Explore Now", link: "/what-is-cmv/" },
    },
    {
      image: whatIsLivtencity,
      text: (
        <>
          What is <br tw="hidden lg:inline-block" />
          <span tw="uppercase">Livtencity?</span>
        </>
      ),
      button: { text: "Learn More", link: "/what-is-livtencity/" },
    },
    {
      image: support,
      text: (
        <>
          Support & resources
          <br />{" "}
          <span tw="font-extralight italic lg:font-black">
            (Apoyos y recursos)
          </span>
        </>
      ),
      button: { text: "Find Resources", link: "/resources/" },
      addedStyle: tw`w-[54px] xl:w-[75px]`,
    },
  ]

  return (
    <div tw="flex flex-wrap -mx-[16px] lg:mx-auto justify-between">
      {cards.map((card, i) => {
        return (
          <div
            key={i}
            tw="text-white text-center font-black rounded-md relative w-full pt-[70px] pb-[75px] px-4 mb-8 [clip-path: polygon(30px 0, 100% 0, 100% 100%, 0 100%, 0 30px)] 
          md:(mb-0 w-[calc((100% - 32px) / 3)] px-6 py-12 flex flex-col items-center justify-between [clip-path: polygon(45px 0, 100% 0, 100% 100%, 0 100%, 0 45px)]) 
          xl:(py-16 w-[calc((100% - 45px) / 3)] [clip-path: polygon(63px 0, 100% 0, 100% 100%, 0 100%, 0 63px)])"
          >
            <GatsbyImage
              image={getImage(data.background)}
              style={{
                width: "100%",
                borderRadius: "inherit",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "-1",
                pointerEvents: "none",
              }}
              alt=""
            />
            <img
              alt=""
              css={[tw`mx-auto mb-9 w-20 xl:w-28`, card.addedStyle]}
              src={card.image}
            />
            <p tw="text-2xl mb-10 leading-7 md:(text-xl leading-normal) xl:(text-27)">
              {card.text}
            </p>
            <Button link={card.button.link} buttonClass="gtm-cta-nav">
              {card.button.text}
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default ThreeCards
