import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../components/button"
import Container from "../components/container"
import Cta from "../components/cta"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Callout from "../components/callout"
import ThreeCards from "../components/threeCards"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "home/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 570
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      ctaImage: file(relativePath: { eq: "home/cta.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 460
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      smallImage: file(relativePath: { eq: "backgrounds/cta-small-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1440
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
      largeImage: file(relativePath: { eq: "backgrounds/cta-large-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Hero styles={tw`lg:(mb-16) xl:(mb-[110px])`}>
        <Container>
          <div tw="grid grid-cols-4 gap-x-7 lg:(grid-cols-12)">
            <div tw="col-span-full lg:col-span-6">
              <h1 tw="font-light text-[32px] leading-9 mb-3 lg:(text-[37px] mb-2 -mr-12 leading-snug) xl:(text-[53px] mb-6 [line-height: 1.4] -mr-[5rem])">
                <span tw="block font-bold italic text-base mb-4 opacity-80 lg:(text-xl mb-2) xl:(text-3xl mb-6)">
                  You&apos;ve had your transplant,
                </span>
                <span tw="block uppercase font-extrabold">
                  but you&apos;re dealing with CMV{" "}
                  <span tw="font-light inline-block mb-1 lg:(inline)">
                    (CYTOMEGALOVIRUS). <br />
                    <span tw="italic text-2xl leading-6 lg:(block leading-loose) xl:(text-32 leading-snug)">
                      [SAI-TOW-MEH-GUH-LOW-VAI-RUHS]
                    </span>
                  </span>
                </span>
                <span tw="block uppercase font-extrabold xl:(mt-4)">
                  Now what?
                </span>
              </h1>
              <p tw="font-light text-base w-3/4 pr-9 lg:(text-base pr-3 pt-3 w-full) xl:(text-21 pr-[72px])">
                <span tw="font-black">
                  You&apos;ve spent a long time preparing for your transplant,
                  and now you&apos;re ready to focus on healing.
                </span>{" "}
                But recovery can be challenging, especially if{" "}
                <br tw="md:(hidden)" />
                you learn that you have an infection called CMV.
              </p>
            </div>
            <div tw="col-span-full lg:(relative col-span-6)">
              <GatsbyImage
                image={getImage(data.heroImage)}
                css={[
                  tw`absolute bottom-[-100px] right-[-110px] w-[311px] lg:(block w-[88%] right-0 bottom-[unset] top-[-50px])`,
                ]}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Hero>

      <section tw="pb-36 lg:pb-48 xl:pb-60">
        <Container tw="mb-10">
          <h2 tw="uppercase inline-block w-10/12 mb-4 text-32 lg:text-34 xl:text-5xl [background-image: linear-gradient(123deg, #7C45FF 40%, #40016D 97%)] [background-clip: text] [-webkit-text-fill-color: transparent]">
            Learning More <span tw="font-extrabold">Can Help</span>
          </h2>
          <Callout>
            <p tw="lg:text-center">
              This site can help you understand life with CMV after transplant.
              <br tw="hidden lg:block" /> You can also learn about{" "}
              <span tw="font-bold">LIVTENCITY</span> - a treatment you may be
              prescribed.
            </p>
          </Callout>
        </Container>
      </section>

      <Cta data={data}>
        <Container tw="mt-[-190px] mb-12 md:mb-20 lg:(mt-[-240px] mb-14) xl:(mt-[-280px] mb-24)">
          <ThreeCards />
        </Container>
        <section>
          <Container tw="lg:(flex flex-row-reverse px-14) xl:(pl-36)">
            <div tw="lg:(flex flex-col justify-center ml-12) xl:(ml-24)">
              <p tw="mr-20 lg:(mr-0)">
                <span tw="font-bold italic lg:(text-22) xl:(text-32)">
                  There&apos;s a lot to learn about
                </span>
                <span tw="relative mt-2.5 mb-[145px] uppercase block text-[28px] leading-tight font-light [-webkit-text-fill-color: transparent] [-webkit-background-clip: text] bg-ctaText lg:(mb-4 text-34) xl:(text-5xl leading-[1.3125])">
                  CMV after different types of transplants.
                </span>
              </p>
              <div tw="text-center lg:(text-left ml-2) xl:(ml-3)">
                <Button link="/what-is-cmv/" buttonClass="gtm-cta-nav">
                  Ready to get started?
                </Button>
              </div>
            </div>
            <GatsbyImage
              image={getImage(data.ctaImage)}
              css={[
                tw`transform scale-x-[-1] absolute -right-10 bottom-[75px] max-w-[230px] lg:(transform-none relative right-0 bottom-0 max-w-[340px]) xl:(max-w-[460px] flex-none)`,
              ]}
              objectFit="cover"
              objectPosition="top center"
              alt=""
            />
          </Container>
        </section>
      </Cta>
    </Layout>
  )
}

export const Head = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "LIVTENCITY® (maribavir) for Patients",
    url: "https://www.explorelivtencity.com/",
  }

  return (
    <>
      {/* OneTrust Cookies Consent Notice start for explorelivtencity.com */}
      <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/885b883b-89ce-4a09-9204-599efa99c1cb/OtAutoBlock.js"
      />
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="885b883b-89ce-4a09-9204-599efa99c1cb"
      />
      <script type="text/javascript">{function OptanonWrapper() {}}</script>
      {/* OneTrust Cookies Consent Notice end for explorelivtencity.com */}

      <Seo
        title="LIVTENCITY® (maribavir) | Patient Information"
        description="View patient information on LIVTENCITY® for post-transplant refractory or resistant CMV. Find resources to help you understand and manage your condition. See full Safety and Patient Information."
      >
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Seo>
    </>
  )
}

export default IndexPage
